<!-- 弹窗 -->
<template>
  <div class="msg"  @click.stop="nothing">
  <div class="search">
       <label>搜索</label> <input type="text"  placeholder="" name="name"  @input="print($event.target.value)">

  </div>

    <div class="head">
      <div class="item1">门店名称</div>
      <div class="item2">地址</div>
      <div class="item3">类别</div>
    </div>
    <div class="body" v-for="(item, i) in list" :key="i">
      <div class="item1">
        {{ item.name }} 
      </div>
      <div class="item2">
        {{ item.address }}
      </div>
      <div class="item3">
        {{ item.type }}
      </div>
    </div>
  </div>
</template>

<script>
import shop from "../assets/shop.json";
export default {
  name: "Pop",
  props: {
    msg: String,
  },
  data() {
    return {
      list: shop,
      list1:shop
    };
  },
  methods:{
    nothing(){

    },
    print(e){
      console.log(e)
      this.list = []
      for(let i=0;i<this.list1.length;i++){
        let str = this.list1[i].name+this.list1[i].address
        if(str.search(e)>-1){
          this.list.push(this.list1[i])
        }
      }
    }
  }
};
</script>
<style  scoped>
.search{
  padding: 5px;
}
.head {
  display: flex;
  background: #b9dcf2;
  height: 40px;
  align-items: center;
  justify-content: center;
    font-weight: bold;
}
.head .item1 {
  width: 42%;
  flex-grow: 1;
}
.head .item2 {
   width: 42%;
    flex-grow: 1;
}
.head .item3 {
  width: 12%;
   flex-grow: 1;
}
.body {
  display: flex;
  /* background: #b9dcf2; */
  height: 40px;
 align-items: center;
    justify-content: space-around;
}
.body .item1{
  font-size: 12px;
  width: 42%;
  
  border:1px solid #185fb9;
  border-right: 0;
  flex-grow: 1;
  line-height: 40px;
  height: 40px;
  overflow: hidden;
}
.body .item2{
  font-size: 12px;
  width: 42%;
  
  border:1px solid #185fb9;
  border-right: 0;
  flex-grow: 1;
  line-height: 40px;
  height: 40px;
    overflow: hidden;
}
.body .item3{
  font-size: 12px;
  width: 12%;
  
  border:1px solid #185fb9;
  flex-grow: 1;
  line-height: 40px;
  height: 40px;
    overflow: hidden;
}
.msg {
  position: fixed;
  left: 50%;
  width: 80%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #185fb9;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  height: 80%;
  overflow: scroll;
  padding-bottom: 5px;
}
</style>