<template>
  <div class="home" @click="closePop()">
    <Pop v-if="showPop" msg="" />
    <div :style="bgImg">
      <!-- 通用 -->
      <div :style="center">
        <div
          v-for="(item, i) in box_list"
          :key="i"
          :style="box"
          @click="goto(item)"
        ></div>
      </div>
      <div v-if="is_box2" :style="box2" @click="goto(id)"></div>
      <!-- 郑州打开商户地址 -->
      <div v-if="is_box3" :style="box3" @click.stop="show1()"></div>
      <!-- 开封456 -->
      <div v-if="is_box4" :style="center4">
        <div
          v-for="(item, i) in box_list4"
          :key="i"
          :style="box4"
          @click="goto(item)"
        ></div>
      </div>
      <div v-if="is_box5" :style="center5">
        <div
          v-for="(item, i) in box_list5"
          :key="i"
          :style="box5"
          @click="goto(item)"
        ></div>
      </div>
      <div v-if="is_box6" :style="center6">
        <div
          v-for="(item, i) in box_list6"
          :key="i"
          :style="box6"
          @click="goto(item)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pop from "@/components/Pop.vue";
let that;

export default {
  name: "Home",
  components: {
    Pop,
  },
  data() {
    return {
      showPop: false,
      box_list: [],
      box_list4: [],
      box_list5: [],
      box_list6: [],
      is_box2: false,
      is_box3: false,
      is_box4: false,
      is_box5: false,
      is_box6: false,
      box3: {
        position: "absolute",
        justifyContent: "center",
        display: "flex",
        //  border:'1px solid red'
      },
      box2: {
        position: "absolute",
        justifyContent: "center",
        display: "flex",
        //  border:'1px solid red'
      },
      bg: {},
      box: {
        // border: "1px solid black",
      },
      box4: {
        // border: "1px solid black",
      },
      box5: {
        // border: "1px solid black",
      },
      box6: {
        // border: "1px solid black",
      },
      id: 1,
      //开封456
      center4: {},
      center5: {},
      center6: {},
      //通用
      center: {
        position: "absolute",
        justifyContent: "center",
        // width: '100%',
        display: "flex",
        // marginRight:'10px'
      },
      bgImg: {
        height: "100vh", //这里一定要设置高度 否则背景图无法显示
        backgroundRepeat: "no-repeat",
      },
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = Number(this.$route.query.id);
    }
    console.log(this.id);
    if (this.id == 7) {
      document.title = "乐享郑州文旅";
    }
    that = this;
    //开始背景图片自适应
    that.setImgSize();
    //屏幕尺寸改变的监听方法
    window.onresize = () => {
      that.setImgSize();
    };
  },
  beforeRouteEnter(to, from, next) {
    // 添加背景色 margin:0;padding:0是为了解决vue四周有白边的问题
    document.querySelector("body").setAttribute("style", "margin:0;padding:0");
    next();
  },
  beforeRouteLeave(to, from, next) {
    // 去除背景色
    document.querySelector("body").setAttribute("style", "");
    next();
  },
  methods: {
    //显示可用地址
    show1() {
      console.log("showpop");
      this.showPop = true;
    },
    closePop() {
      this.showPop = false;
    },
    goto(couponId) {
      console.log(couponId);
      // upsdk.pluginReady(function () { // eslint-disable-line
      //   // 前端API调用
      //   upsdk.createWebView({ // eslint-disable-line
      //     url: "https://mall.95516.com/mobile/rncouponinfo/"+couponId, // webview 容器中运行的目标页面地址
      //     isFinish: "0", //是否关闭当前的窗口,'1':关闭，'0':不关闭
      //   });
      // });
      // window.open("upwallet://rn/rncoupondetail?couponId="+couponId)
      // window.location.href =
      //   "upwallet://rn/rncoupondetail?couponId=" + couponId;
      // window.location.href ="https://content.95516.com/UCIWeb/outputWeb/coupon/coupon.html#/couponDetail?couponId="+couponId;
      //  window.location.href="https://mall.95516.com/mobile/rncouponinfo/"+couponId;
      window.open("https://mall.95516.com/mobile/rncouponinfo/" + couponId);
      // this.$router.push({
      //   path: "/about",
      //   query: {
      //     name: type,
      //   },
      // });
      // this.$router.push({
      // 	name: "about", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
      // 	params: {
      // 		name: "ming",
      // 		age: type
      // 	}
      // });
    },
    setImgSize() {
      let width = document.body.clientWidth; //获取可见区域的宽
      console.log(width);
      //  let height = document.body.clientHeight+"px"; //获取可见区域的高
      let height; //背景高度

      let box_w, box_h; //热点宽度和高度
      let mg, ml, box_mg; //热点顶部，左边，右间距
      const temp = JSON.stringify(this.center);
      switch (this.id) {
        case 1:
          console.log("三门峡");
          height = (width * 3398) / 600;
          //  that.$set(that.bgImg, "background-image", "url(" + require("../assets/bg_smx.jpg") + ")");
          this.bgImg.backgroundImage =
            "url(" + require("../assets/bg_smx.jpg") + ")";
          //3102021091402484,测试 3102021082798460
          this.box_list = [
            "3102021092305716",
            "3102021092305764",
            "3102021092305763",
          ];
          console.log(this.box_list);
          box_w = width / 4;
          box_h = width / 2.6;
          mg = width * 1.68; //距离顶部
          ml = width / 12; //距离左边
          box_mg = width / 28; //方框间距
          break;
        case 2:
          console.log("开封");

          this.bgImg.backgroundImage =
            "url(" + require("../assets/bg_kf.jpg") + ")";
          height = (width * 2711) / 600;
          this.box_list = [
            "2102021092808209",
            "2102021092808210",
            "2102021092808013",
          ];
          box_w = width * 0.21;
          box_h = width * 0.3;
          box_mg = width / 22; //方框间距
          mg = width * 2.0; //距离顶部
          ml = width * 0.13; //距离左边

          //box4
          this.is_box4 = true;

          this.center4 = JSON.parse(temp);
          this.box_list4 = [
            "2102021092808616",
            "2102021092808615",
            "2102021092808614",
          ];
          this.center4.marginTop = width * 2.5 + "px";
          this.center4.marginLeft = width * 0.13 + "px";
          this.box4.height = width * 0.3 + "px";
          this.box4.width = width * 0.21 + "px";
          this.box4.marginRight = width * 0.04 + "px";
          //box5
          this.is_box5 = true;
          this.center5 = JSON.parse(temp);
          this.box_list5 = [
            "2102021092808613",
            "2102021092808612",
            "2102021092808611",
          ];
          this.center5.marginTop = width * 3.02 + "px";
          this.center5.marginLeft = width * 0.13 + "px";
          this.box5.height = width * 0.3 + "px";
          this.box5.width = width * 0.21 + "px";
          this.box5.marginRight = width * 0.04 + "px";
          //box6
          this.is_box6 = true;
          this.center6 = JSON.parse(temp);
          this.box_list6 = ["2102021092808610", "2102021092808609"];
          this.center6.marginTop = width * 3.54 + "px";
          this.center6.marginLeft = width * 0.23 + "px";
          this.box6.height = width * 0.3 + "px";
          this.box6.width = width * 0.21 + "px";
          this.box6.marginRight = width * 0.08 + "px";
          break;
        case 3:
          console.log("焦作");

          this.bgImg.backgroundImage =
            "url(" + require("../assets/bg_jz.jpg") + ")";
          height = (width * 2423) / 600;
          this.box_list = ["3102021091804477", "3102021091804476"];
          box_w = width / 3.7;
          box_h = width / 3;
          mg = width * 1.5; //距离顶部
          ml = width / 5; //距离左边
          box_mg = width / 18; //方框间距
          break;
        case 5:
          console.log("兰考");
          //  that.$set(that.bgImg, "background-image", "url(" + require("../assets/bg.png") + ")");
          this.bgImg.backgroundImage =
            "url(" + require("../assets/bg_lk.jpg") + ")";
          height = (width * 2308) / 600;
          this.box_list = ["2102021082697877"];
          box_w = width / 1.2;
          box_h = width / 4;
          mg = width * 1.22; //距离顶部
          ml = width / 14; //距离左边
          box_mg = 0; //方框间距
          //第二个按钮
          this.is_box2 = true;
          this.box2.marginTop = width * 2.14 + "px";
          this.box2.marginLeft = width * 0.1 + "px";
          this.box2.height = width * 0.1 + "px";
          this.box2.width = width * 0.8 + "px";
          this.id = "2102021082697877";
          break;
        case 6:
          console.log("鹤壁");
          //  that.$set(that.bgImg, "background-image", "url(" + require("../assets/bg.png") + ")");
          this.bgImg.backgroundImage =
            "url(" + require("../assets/bg_hb.jpg") + ")";
          height = (width * 1350) / 600;
          this.box_list = ["3112021092707174"];
          box_w = width / 5;
          box_h = width / 3.6;
          mg = width * 0.85; //距离顶部
          ml = width / 5; //距离左边
          box_mg = 0; //方框间距
          break;
        case 7:
          console.log("郑州");
          //  that.$set(that.bgImg, "background-image", "url(" + require("../assets/bg.png") + ")");
          this.bgImg.backgroundImage =
            "url(" + require("../assets/bg_zz.jpg") + ")";
          height = (width * 2688) / 600;
          this.box_list = [
            "2102021092808093",
            "2102021092808201",
            "2102021092808202",
          ];
          box_w = width / 4;
          box_h = width / 2.8;
          mg = width * 1.4; //距离顶部
          ml = width / 10; //距离左边
          box_mg = width / 50; //方框间距
          this.is_box3 = true;
          //试用商户范围
          this.box3.marginTop = width * 3.26 + "px";
          this.box3.marginLeft = width * 0.1 + "px";
          this.box3.height = width * 0.1 + "px";
          this.box3.width = width * 0.8 + "px";
          break;
      }

      that.$set(that.bgImg, "backgroundSize", width + "px " + height + "px");
      that.$set(that.bgImg, "height", height + "px");
      that.$set(that.center, "margin-top", mg + "px");
      that.$set(that.center, "margin-left", ml + "px");
      that.$set(that.box, "width", box_w + "px");
      that.$set(that.box, "height", box_h + "px");
      that.$set(that.box, "margin-right", box_mg + "px");
      // that.$set(that.bg,'width',width+"px");
      // that.$set(that.bg,'height',height+"px");
    },
  },
};
</script>
<style scoped >
</style>